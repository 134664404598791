import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FullscreenDialog from '@/components/templates/FullscreenDialog.vue';
import ConfirmationFooter from '@/components/molecules/ConfirmationFooter.vue';
import { UiFacility } from '@/models/facility';
import { enterFullScreen } from '@/store/view';

@Component({
  components: { FullscreenDialog, ConfirmationFooter }
})
export default class TabletViewConfirmation extends Vue {
  @Prop({ type: Boolean }) show!: boolean;

  @Prop({ type: Object }) facility!: UiFacility;

  redirectToFacilityDashboard(): void {
    enterFullScreen();
    this.$router.replace({
      name: 'facilityDashboard',
      query: { facility_id: this.facility.facility_id },
    });
  }
}
