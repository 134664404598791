import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import { UiFacility } from '@/models/facility';
import Loading from '@/components/atoms/Loading.vue';
import BuildingsDropdown from '@/components/molecules/BuildingsDropdown.vue';
import FacilityLinkItem from '@/components/atoms/FacilityLinkItem.vue';
import TabletViewConfirmation from '@/components/organisms/TabletViewConfirmation.vue';
import FacilityHelper, { divideByFloorsAndPaginate, FloorPage } from '@/helpers/facility';
import { FacilityStatusDoorStatusEnum } from '@/api/models';
import { TenantDetails } from '@/store/types/modules';

@Component({ components: { Loading, BuildingsDropdown, FacilityLinkItem, TabletViewConfirmation } })
export default class FacilityDashboardLinks extends Vue {
  private facilities: UiFacility[] = [];

  private loading = true;

  private buildings: string[] = [];

  private currentBuilding = '';

  private showTabletViewConfirmation = false;

  private redirectFacility: UiFacility = { 
    facility_id: 'id',
    facility_name: 'name',
    is_device_dead: false,
    is_facility_used: false,
    door_status: FacilityStatusDoorStatusEnum.Open
  }; // to avoid Vue warning

  @State('tenant') tenant!: TenantDetails;

  get currentTenantId(): string {
    return this.tenant.current.id;
  }

  get facilitiesInCurrentBuildiing(): UiFacility[] {
    return this.facilities.filter(f => (f.features?.building === this.currentBuilding));
  }

  get facilitiesByFloor(): FloorPage[] {
    // we don't need pagination so setting the pageSize to maximum
    const facilitiesByFloor = divideByFloorsAndPaginate(this.facilitiesInCurrentBuildiing, this.facilities.length);
    facilitiesByFloor.sort((f1, f2) => (parseInt(f1.floorName, 10) > parseInt(f2.floorName, 10) ? 1 : -1 ));
    return facilitiesByFloor;
  }

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  async mounted(): Promise<void> {
    let fs = await this.facilityHelper.fetchFacilities(this.currentTenantId);
    // filter out custom facilities
    fs = fs.filter( f => (f.calendar_id && f.calendar_integration_id));
    this.facilities = fs ? [...fs] : [];
    if (this.facilities.length) {
      this.buildings = this.facilityHelper.buildings(this.facilities);
      if (this.buildings.length) {
        [this.currentBuilding] = this.buildings;
      }
    }
    this.loading = false;
  }

  showConfirmation(facility: UiFacility): void {
    this.redirectFacility = facility;
    this.showTabletViewConfirmation = true;
  }
}
